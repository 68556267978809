import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import { HeaderPlaceholder, Underline, Button } from "../../styles/ui.styles";
import { SwiperWrapper } from "../../styles/swiper.styles";
import SubscribeForm from "../../components/subscribeForm";
import { StaticImage } from "gatsby-plugin-image";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { Navigation, Thumbs } from "swiper";
SwiperCore.use([Navigation, Thumbs]);

import { FaCheck } from "react-icons/fa";
import Configurator from "../../components/configurator/configurator";
import useScrollTo from "../../lib/scrollto";
import ExpandableItem from "../../components/expandableItem";

const CoachBoatPro: React.FC = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const { scrollTo } = useScrollTo();
  return (
    <>
      <SEO title={"Coachboat Pro"} description={"Coachboat Pro"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <SliderSection>
            <div>
              <h1>Coachboat Pro</h1>
              <p>
                Coachboat Pro is an advanced solution designed specifically for
                coaches, which allows monitoring and analysis of wind, current
                and waves in real time. After the training sync with your own
                cloud for further analysis.
              </p>
              <Buttons>
                <Button
                  onClick={() => scrollTo("#configurator", -100)}
                  primary={true}
                >
                  CONFIGURE
                </Button>
              </Buttons>
            </div>
            <SwiperWrapper>
              <Swiper
                // loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                className="mainSwiper"
              >
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/coach_boat_pro1.jpg"}
                    alt={"Coachboat Pro"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/coach_boat_pro2.jpg"}
                    alt={"Coachboat Pro"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/coach_boat_pro3.jpg"}
                    alt={"Coachboat Pro"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                onSwiper={(swpr: SwiperCore) => setThumbsSwiper(swpr)}
                loop={false}
                spaceBetween={10}
                slidesPerView={3}
                freeMode={false}
                watchSlidesProgress={true}
                className="thumbnailSwiper"
              >
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/coach_boat_pro1.jpg"}
                    alt={"Coachboat Pro"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/coach_boat_pro2.jpg"}
                    alt={"Coachboat Pro"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/coach_boat_pro3.jpg"}
                    alt={"Coachboat Pro"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
              </Swiper>
            </SwiperWrapper>
          </SliderSection>
          <Title id="why" style={{ marginTop: "128px" }}>
            <h2>Why Coachboat Pro?</h2>
            <Underline />
          </Title>
          <Whysection>
            <p>
              The use of sophisticated tools that provide instant feedback and
              insights on a sailor’s performance are becoming more and more
              essential. Accurate knowledge of wind changes is essential in
              high-level racing, however, without automated data collection and
              analytics tools, coaches will not be able to observe wind
              characteristic. Fastrrr offers an affordable system that provides
              real-time analysis of a sailboat and the surrounding environment.
              Data is collected in an online platform which is tailored to the
              needs of professional sailors and their coaches. This data is then
              used to provide prompt advice for sailors on how to optimize
              performance.
            </p>
          </Whysection>

          <Title>
            <h2>Topology</h2>
            <Underline />
          </Title>

          <div style={{ width: "100%" }}>
            <StaticImage
              style={{ width: "100%" }}
              src={"../../images/dinghy_graph.png"}
              alt={"DinghyEdition Graph"}
              objectFit={"contain"}
            />
          </div>

          <Title>
            <h2>Features</h2>
            <Underline />
          </Title>
          <FeaturesGrid>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Real time analysis
                  </StyledListElement>
                  <p>
                    Coachboat Pro records and analyzes data from the wind
                    instrument, which is displayed on a user-friendly interface
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Compact and modular design
                  </StyledListElement>
                  <p>
                    The anemometer is located on the top of a carbon mast. The
                    tensioning ropes can be attached to the mast, which ensure
                    accurate measurement even in choppy water. The complete
                    system is easy to carry and has a modular design.{" "}
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Smartphone integration
                  </StyledListElement>
                  <p>
                    Understand the breeze, and observe trending wind patterns
                    over time with your smartphone or tablet
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Ready for training
                  </StyledListElement>
                  <p>
                    The Coachboat Pro is an optional part of the BravoBoats
                    product portfolio and can also be fitted to VSR powerboats
                    on request.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Cloud system
                  </StyledListElement>
                  <p>
                    Our cloud gives you the opportunity to save all your sailing
                    sessions, and review it any time, you can store your race
                    results and get a perfect picture of your improvement.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Easy data access
                  </StyledListElement>
                  <p>
                    The device can be integrated into the boat’s existing NMEA
                    system (2000 or 0183)
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Data security
                  </StyledListElement>
                  <p>No one has access to your own sailing data.</p>
                </li>
              </ul>
            </div>
          </FeaturesGrid>
          <Title>
            <h2>What’s more?</h2>
            <Underline />
          </Title>
          <WhatsMoreGrid>
            <div>
              <p>
                <strong>
                  Coachboat Pro is a portable wind measurement solution for
                  sailing coaches,
                </strong>
                <br />
                <br />
                that enables you to visualize trending wind patterns in
                real-time, save it in the database for further analyzing and
                searching for trends. Coachboat Pro records and analyzes data
                from the wind instrument, which is displayed on a user-friendly
                interface. Changes in the wind can be easily tracked on the
                chart. Coachboat Pro can be integrated into additional tracking
                systems developed by Fastrrr, such as DinghyEdition or
                YachtEdition.
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Waterproof &amp; sturdy
                  </StyledListElement>
                  <p>
                    Made to work in the toughest conditions. Coachboat Pro has
                    no moving parts, nothing to calibrate, and features single
                    button operation.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Wireless connectivity
                  </StyledListElement>
                  <p>Bluetooth 4.0 and WIFI</p>
                </li>
              </ul>
            </div>
          </WhatsMoreGrid>
          <Title>
            <h2>More details</h2>
            <Underline />
          </Title>
          <ExpandableGrid>
            <ExpandableItem
              title={"What’s in the box?"}
              expandableText={
                "– Coachboat Pro instrument\n– Ultrasonic wind meter\n– Power cable\n– N2K drop cable 1.8m\n– Carbon weather mast\n\nPlease notice our product manual is only available online. Please download the latest version of manual from the support page."
              }
            />
            <ExpandableItem
              title={"Features"}
              expandableText={
                "– Motion corrected true wind direction\n– Realtime wind statistics, trends and data filtering\n– Data logger for post session analysis\n– Waterproof (IPX8) to 3m\n– Two-year warranty"
              }
            />
            <ExpandableItem
              title={"Technical specifications"}
              expandableText={
                "– GPS networks: multi-GNSS (GPS/QZSS, GLONASS and BeiDou)\n– GPS Frequency: 10 Hz\n– Inertial Measurement Unit: 9 axis 1kHz\n– Compass: 3 axis magnetometer, compensated with 3 gyroscopes\n– Wind sensor sample rate: 20Hz\n– Wind speed accuracy: ±0.1 m/s at 10m/s\n– Wind direction accuracy: ±1º\n– Size: 140x80x75 mm\n– Weight: 450gr\n– Supply voltage: 12V DC"
              }
            />
            <ExpandableItem
              title={"Shipping details"}
              expandableText={
                "Your order will be processed and shipped from our office in the Hungary (Veszprem). Depending on where your order is shipped to, the following approximate delivery times will apply: \n\n – Europe: 3-5 business days* \n – Outside Europe: 5-7 business days* \n – Worldwide shipping will be handled by UPS (Track and Trace included) \n\n * No rights can be derived from these approximate delivery times"
              }
            />
          </ExpandableGrid>
        </Wrapper>
        <div id={"configurator"} />
        <Configurator forBoatType={"coachboat"} />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default CoachBoatPro;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;

  p {
    color: var(--color-dark-grey);
  }
`;

const SliderSection = styled.section`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 64px;

  div:first-child {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 32px;
`;

const Title = styled.div`
  margin: 70px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0;
    margin-top: 32px;
  }
  div {
    margin: 32px 0;
  }
`;

const Whysection = styled.section`
  columns: 400px 2;
  column-gap: 32px;
  p {
    margin-top: 0;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
    background: #f3f3f3;
    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StyledListElement = styled.strong`
  display: flex;
  align-items: center;
  svg {
    min-width: 16px;
  }
`;

const WhatsMoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
  }

  div:nth-child(2) {
    background: #f3f3f3;

    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ExpandableGrid = styled.div`
  p {
    color: unset;
  }
  display: grid;
  white-space: pre-line;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const Graphs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
